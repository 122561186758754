import React from 'react'
import ServicesHeader from '../Components/services components/ServicesHeader'
import Theme1 from '../Components/Themes/Theme1'
import Theme2 from '../Components/Themes/Theme2'
import Theme3 from '../Components/Themes/Theme3'
function NewPage() {
  return (
    <div  class="page-wrapper-div"  >
            <ServicesHeader/>
            <h2 class="page-heading">ADR Professionals</h2>

    <Theme3/>
</div>

  )
}

export default NewPage