import React from 'react'

function Footer() {
  return (
    <div className="footer-wrap">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-6">&copy 2021 peacegate. All rights reserved.</div>
            <div className="col-lg-6 col-md-6">Designed by <a href="https://www.initsolutions.in/" target="_blank">InIT
                    Solutions</a></div>
        </div>
    </div>
</div>
  )
}

export default Footer