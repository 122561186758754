import React,{useState,useEffect,useRef} from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Tab, Nav} from 'react-bootstrap';
import { ImageUrl } from '../Constants/Constants';
import {useNavigate } from 'react-router-dom';
import Dots from './Dots';
function ArticleSample2(props) {
    const Category=props.category
   const contents=props.contents
   const navigate = useNavigate();
   //console.log(contents)
    const [activeTab, setActiveTab] =useState(0)
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
      }; 
  
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
      function formattedDate(DateTime){
        const dateTimeString = DateTime;
        const date = new Date(dateTimeString);
      
        // Extract the date component from the Date object and format it
        return date.toLocaleDateString('en-US',{
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            daySuffix: (day) => {
              switch (day) {
                case 1:
                case 21:
                case 31:
                  return 'st';
                case 2:
                case 22:
                  return 'nd';
                case 3:
                case 23:
                  return 'rd';
                default:
                  return 'th';
              }
            }
            });
      }
  

  return (
    <section className="container articles-wrap">
    <h2>{props.Name}</h2>
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
     {Category.length>1?<Nav   className="justify-content-center">
      {Category.map((item, index)=> (
          <Nav.Item key={item.Id}>
          <Nav.Link key={item.Id} eventKey={index}>{item.Heading}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>:null}
      <Tab.Content>
      {Category.map((item, index) => (
      <Tab.Pane key={item.Id} eventKey={index}>
    <div key={item.Id} className="articles-div">
    <Carousel key={item.Id} responsive={responsive}   swipeable={false}
  draggable={false}
  showDots={true}   containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]} arrows={false} renderButtonGroupOutside={true} itemPadding={[10, 25]} customDot={<Dots />}>
    {contents.filter(content => content.AnnouncementGroupId===item.Id).map(content => (
  <div key={content.Id}>
  <button key={content.Id} className="article-item" style={{padding:'20px'}}>
                        <span key={content.Id}>
                            <img src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`} alt="peacegate"/>
                            <font className="link-icon"><i className="fas fa-link"></i></font>
                            <h6>
                            {item.Heading}<font>{formattedDate(item.Date)}</font>
                            </h6>
                        </span>
                        <div  className="article-content">
                            <h5>{content.Heading}</h5>
                            <p dangerouslySetInnerHTML={{__html: content.Description}} style={{height: '50px', overflow: 'hidden'}} ></p>
                            <button style={{ backgroundColor: 'var(--blue)', color: '#fff' , borderRadius: '5px'}} onClick={() => navigate('/announcement-description', { state: content })} >Read More</button>
                           
                        </div>
                    </button>
  </div>
  ))}
</Carousel>
</div>
</Tab.Pane>
))}
</Tab.Content>
    </Tab.Container>
   </section>
  )
}

export default ArticleSample2