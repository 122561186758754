
import React,{useState,useEffect} from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Tab, Nav} from 'react-bootstrap';
import { ImageUrl } from '../Constants/Constants';
import Dots from './Dots';
function SampleVideos2(props) {
    const Category=props.category
    const contents=props.contents
    //console.log(Category)
    //console.log(contents)
     const [activeTab, setActiveTab] =useState(0)
     const handleTabSelect = (tab) => {
         setActiveTab(tab);
       }; 
   
     const responsive = {
         desktop: {
           breakpoint: { max: 3000, min: 1024 },
           items: 4,
           slidesToSlide: 1 // optional, default to 1.
         },
         tablet: {
           breakpoint: { max: 1024, min: 464 },
           items: 2,
           slidesToSlide: 1 // optional, default to 1.
         },
         mobile: {
           breakpoint: { max: 464, min: 0 },
           items: 1,
           slidesToSlide: 1 // optional, default to 1.
         }
       };
  return (
    <section className="trendingvideos articles-wrap">
    <div className="container">
    <h2>{props.Name}</h2>
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
     {Category.length>1?<Nav   className="justify-content-center" >
      {Category.map((item, index)=> (
          <Nav.Item key={item.Id}>
          <Nav.Link key={item.Id}  eventKey={index}  >{item.Heading}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>:null}
      <Tab.Content>
      {Category.map((item, index) => (
      <Tab.Pane key={item.Id} eventKey={index}>
    <Carousel key={item.Id} responsive={responsive}
            swipeable={false}   draggable={false}
      infinite={true}
      autoPlay={true}
      removeArrowOnDeviceType={['tablet', 'mobile']}
      dotListclass="custom-dot-list-style"
      showDots={true} itemPadding={[10, 25]} arrows={false} customDot={<Dots />}>
    {contents.filter(content => content.AnnouncementGroupId===item.Id).map(content => (
              <div  key={content.Id} className="video-item" style={{padding:'20px'}}>
              <button key={content.Id} className="trendvideo-item">
                  <span key={content.Id}>
                      <img src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`} alt="peacegate"/>
                  </span>
                  <div  className="videocontent">
                      <font className="pulse-btn"><i className="fas fa-play"></i></font>
                      <h6>{content.Heading}</h6>
                  </div>
              </button>
          </div>
  ))}
</Carousel>
</Tab.Pane>
))}
</Tab.Content>
</Tab.Container>
</div>
</section>
  )
}

export default SampleVideos2