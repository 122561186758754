import React,{useState, useRef } from 'react'
import { Nav, NavItem,Tab} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { Navigate, useNavigate } from 'react-router-dom';
import { ImageUrl } from '../Constants/Constants';
import Dots from './Dots';
function UpcomingEvents3(props) {
    const Category=props.category
    const contents=props.contents
    //console.log(contents)
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const handleTabSelect = (tab) => {
      setActiveTab(tab);
    }; 
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
  return (
    <section className="upcoming-events-wrap articles-wrap">
    <div className="container">
        <h2>{props.Name}</h2>
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
<Nav className="justify-content-center" >
{Category.map((item, index)=> (
  <NavItem key={item.Id}>
    <Nav.Link key={item.Id} eventKey={index} >
      {item.Heading}
    </Nav.Link>
  </NavItem>
))}
</Nav>
<Tab.Content>
{Category.map((item, index) => (
<Tab.Pane  key={item.Id} eventKey={index}>
        <div key={item.Id} className="eventscontent">
        <Carousel key={item.Id}  responsive={responsive}  showDots={true} arrows={false} customDot={<Dots />}>
        {contents.filter(content => content.AnnouncementGroupId===item.Id).map(content => (
            
                        <div key={content.Id} className="row" style={{padding:'20px'}} >
                            <div className="col-lg-4"><span><img src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`} alt=""/></span></div>
                            <div className="col-lg-8">
                                <div className="event-content-wrap">
                                    <h5>{content.Heading}</h5>
                                    <p dangerouslySetInnerHTML={{__html: content.Description}} style={{height: '50px', overflow: 'hidden'}}></p>
                                    <a style={{cursor: 'pointer'}} onClick={() => navigate('/announcement-description', { state: content })}>Read More</a>
                                </div>
                            </div>
                        </div>
        ))}
                    </Carousel>
                </div>
           </Tab.Pane>
           ))}
            </Tab.Content>
        </Tab.Container>
        </div>
    </section>
  )
}

export default UpcomingEvents3