import React, { useEffect,useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import ImageSlide from '../Components/ImageSlide'
import '../css/MainPage2.css'
import { baseUrl } from '../Constants/Constants'
import axios from 'axios'
import ArticleSample2 from '../Components/ArticleSample2'
import SampleVideos2 from '../Components/SampleVideos2'
import CaptionsHere2 from '../Components/CaptionsHere2'
import UpcomingEvents3 from '../Components/UpcomingEvents3'
import ContactDetails from '../Components/ContactDetails'
import Gallery from '../Components/Gallery'
import Gallery2 from '../Components/Gallery2'
function MainPage() {
  const [Group, setGroup] = useState([])
  const [Category, setCategory] = useState([])
  const [Content, setContent] = useState([])
 // const [isLoading, setIsLoading] = useState(true)
 /* useEffect(() => {
    Promise.all([
      axios.get(baseUrl+`/IIAM_AnnouncementGroup`),
      axios.get(baseUrl+`/IIAM_AnnouncementContent`),
      axios.get(baseUrl+`/IIAM_Announcement`)
    ]).then(([groupResponse, contentResponse, categoryResponse]) => {
      setGroup(groupResponse.data)
      setContent(contentResponse.data)
      setCategory(categoryResponse.data)
      //console.log(contentResponse.data)
      setIsLoading(false)
    })
  }, [])*/
  useEffect(() => {
    axios.get(baseUrl+`/IIAM_AnnouncementGroup`)
    .then(function (response) {
      //console.log(response.data);
      setGroup(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
    axios.get(baseUrl+`/IIAM_Announcement`)
    .then(function (response) {
      //console.log(response.data);
      setCategory(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
    axios.get(baseUrl+`/IIAM_AnnouncementContent`)
    .then(function (response) {
      //console.log(response.data);
      setContent(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

const GroupData = Group.map((item)=>{
  if (item.Type === 1) {
    const CategoryByGroup = Category.filter((data) => data.Type === 1 && data.AnnouncementGroupId === item.Id);
  //console.log(CategoryByGroup)
    const FilteredContents = CategoryByGroup.flatMap((category) => {
      return Content.filter((data) => data.Type === 1 && data.AnnouncementGroupId === category.Id);
    });
  // console.log(FilteredContents)
    return <ArticleSample2 key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents} />;
  }
  
  else if(item.Type===2){
    const CategoryByGroup=Category.filter((data) => data.Type===2 && data.AnnouncementGroupId ===item.Id);
    //console.log(CategoryByGroup)
    const FilteredContents = CategoryByGroup.flatMap((category) => {
      return Content.filter((data) => data.Type === 2 && data.AnnouncementGroupId === category.Id);
    });
    return <SampleVideos2 key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents}/>
  }
    else if(item.Type===3){
      const CategoryByGroup=Category.filter((data) => data.Type===3 && data.AnnouncementGroupId ===item.Id);
   // console.log(item)
   const FilteredContents = CategoryByGroup.flatMap((category) => {
    return Content.filter((data) => data.Type === 3 && data.AnnouncementGroupId === category.Id);
  });
    return <CaptionsHere2 key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents} />
  }
    else if(item.Type===4){
      const CategoryByGroup=Category.filter((data) => data.Type===4 && data.AnnouncementGroupId ===item.Id);
    //console.log(item)
    const FilteredContents = CategoryByGroup.flatMap((category) => {
      return Content.filter((data) => data.Type === 4 && data.AnnouncementGroupId === category.Id);
    });
  return <ImageSlide key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents} />
  }
  else if(item.Type===5){
    const CategoryByGroup=Category.filter((data) => data.Type===5 && data.AnnouncementGroupId ===item.Id);
    //console.log(item)
    const FilteredContents = CategoryByGroup.flatMap((category) => {
      return Content.filter((data) => data.Type === 5 && data.AnnouncementGroupId === category.Id);
    });
    return <UpcomingEvents3 key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents} />
  }
  else if(item.Type===6){
    const CategoryByGroup=Category.filter((data) => data.Type===6 && data.AnnouncementGroupId ===item.Id);
    //console.log(item)
    const FilteredContents = CategoryByGroup.flatMap((category) => {
      return Content.filter((data) => data.Type === 6 && data.AnnouncementGroupId === category.Id);
    });
    return <Gallery key={item.Id}  Name={item.Name} category={CategoryByGroup} contents={FilteredContents} />
  }
  else{
   //console.log(item)
  }
})
    return (
        <>
         <Header/>
         {GroupData}
         <ContactDetails />
        <Footer/>
        </>
      )
}

export default MainPage