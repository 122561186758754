import React from 'react';
import { ImageUrl } from '../../Constants/Constants';
import {useNavigate } from 'react-router-dom';
function DefaultTheme(props) {
 const navigate = useNavigate();
  const Details = props.details;
  const imagepath=props.Imagepath
  return (
    <>
      {Details.map(details => (
        <div className="col-lg-3 col-md-4 col-6" key={details.Id}>
          {imagepath==='topic'?
          <button className="service-item" onClick={() => navigate('/details', { state: details })}>
            <font><i className="fas fa-heart"></i></font>
            <span><img src={ImageUrl+`/assets/images/IIAM_AnnouncementSubContentImages/${details.ImageUrl}`} alt="peacegate"/></span>
            <h6>{details.Heading}</h6>
          </button>: <button className="service-item" onClick={() => navigate('/subdetails', { state: details })}>
            <font><i className="fas fa-heart"></i></font>
            <span><img src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${details.ImageUrl}`} alt="peacegate"/></span>
            <h6>{details.Heading}</h6>
          </button>}
        </div>
      ))}
    </>
  );
}

export default DefaultTheme;
