import React,{useEffect, useState} from 'react'
import peacegatelogo from '../img/peacegate-logo.png';
import banner from "../img/banner-01.jpg"
import Carousel from 'react-bootstrap/Carousel';
import { baseUrl } from '../Constants/Constants';
import axios from 'axios';
import $ from 'jquery';
function Header() {
  const [WebsiteLink, setWebsiteLink] = useState([])
    useEffect(() => {
      axios.get(baseUrl+`/IIAM_AnnouncementsWebsiteContents`)
      .then(response => {
        //console.log(response.data)
        setWebsiteLink(response.data)
      })
        function handleMenuClick() {
          $('.sidemenubar').toggleClass('viewmenu');
          $('#searchic').toggleClass('fa-bars fa-times');
        }
    
        $('.menu-btn').click(handleMenuClick);
    
        return () => {
          $('.menu-btn').off('click', handleMenuClick);
        };
        
      }, []);
      function handleClick(socialamedia) {
        if(socialamedia==='facebook')
        {
          window.open(WebsiteLink[0].FaceBook,'_blank');
        }
        else if(socialamedia==='twitter'){
          window.open(WebsiteLink[0].Twitter,'_blank');
        }
        else if(socialamedia==='linkedin'){
          window.open(WebsiteLink[0].LinkedIn,'_blank');
        } 
        else if(socialamedia==='youtube'){
          window.open(WebsiteLink[0].YouTube,'_blank');
        }       
        else{
          window.open(WebsiteLink[0].Instagram,'_blank');
        }       
      }
    
  return (
    <div>
    <div className="sidemenubar">
    <div className="menusocialmedia">
        <button className="close-btn menu-btn"><i className="fas fa-times"></i></button>
        <button ><i className="fab fa-facebook-f" onClick={() => handleClick("facebook")} ></i></button>
        <button><i className="fab fa-twitter" onClick={() => handleClick("twitter")}></i></button>
        <button><i className="fab fa-linkedin-in" onClick={() => handleClick("linkedin")}></i></button>
        <button><i className="fab fa-instagram" onClick={() => handleClick("instagram")}></i></button>
        <button><i className="fab fa-youtube" onClick={() => handleClick("youtube")}></i></button>
    </div>
  <ul>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
        <li><button>Sample Link</button></li>
    </ul>
</div>

<div className="home-slider">

    <div className="socialmedia-con">
        <button className="menu-btn"><i className="fas fa-ellipsis-v" ></i></button>
        <button className="social-menu"><i className="fab fa-facebook-f" onClick={() => handleClick("facebook")}></i></button>
        <button className="social-menu"><i className="fab fa-twitter" onClick={() => handleClick("twitter")}></i></button>
        <button className="social-menu"><i className="fab fa-linkedin-in" onClick={() => handleClick("linkedin")}></i></button>
        <button className="social-menu"><i className="fab fa-instagram" onClick={() => handleClick("instagram")}></i></button>
        <button className="social-menu"><i className="fab fa-youtube" onClick={() => handleClick("youtube")}></i></button>
    </div>
    <div className="carousel-capt">
        <img src={peacegatelogo} alt="peacegate logo"/>
    </div>
    <Carousel pause={false} controls={false} indicators={false}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner}
          alt="PeaceGate"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={banner}
          alt="PeaceGate"
        />
      </Carousel.Item>
    </Carousel>
</div>
</div>
  )
}

export default Header