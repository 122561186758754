import React,{useState} from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import '../css/Gallery.css'
import { Tab, Nav} from 'react-bootstrap';
function Gallery(props) {
    const Category=props.category
    //console.log(Category)
    const contents=props.contents
    const [activeTab, setActiveTab] =useState(0)
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
      }; 
    const images = [
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/82.jpg?timestamp=03/18/23 11:02:16 AM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/82.jpg?timestamp=03/18/23 11:02:16 AM",
          caption: 'Image 1'
        },
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/84.jpg?timestamp=03/15/23  3:54:08 PM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/84.jpg?timestamp=03/15/23  3:54:08 PM",
          caption: 'Image 1'
        },
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/83.jpg?timestamp=03/15/23  3:53:59 PM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/83.jpg?timestamp=03/15/23  3:53:59 PM",
          caption: 'Image 1'
        },
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/85.jpg?timestamp=03/15/23  3:54:15 PM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/85.jpg?timestamp=03/15/23  3:54:15 PM",
          caption: 'Image 1'
        },
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/82.jpg?timestamp=03/18/23 11:02:16 AM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/82.jpg?timestamp=03/18/23 11:02:16 AM",
          caption: 'Image 1'
        },
        {
          src: 'https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/88.jpg?timestamp=03/13/23 11:30:18 AM',
          thumbnail: "https://pgadmin.initstore.com/assets/images/IIAM_AnnouncementContentImages/88.jpg?timestamp=03/13/23 11:30:18 AM",
          caption: 'Image 1'
        }
        // add more images here...
      ];
      
      const [lightboxOpen, setLightboxOpen] = useState(false);
      const [lightboxIndex, setLightboxIndex] = useState(0);
      
      
  return (
    <section className="articles-wrap" >
        <h2>{props.Name}</h2>
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
        {Category.length>1?<Nav   className="justify-content-center" style={{ paddingBottom: '20px' }}>
      {Category.map((item, index)=> (
          <Nav.Item key={item.Id}>
          <Nav.Link key={item.Id} style={{ color: 'black' }} eventKey={index}>{item.Heading}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>:null}
      <Tab.Content>
      {Category.map((item, index) => (
      <Tab.Pane key={item.Id} eventKey={index}>
    <div className="thumbnail-grid">
    {images.map((image, index) => (
      <img
        key={index}
        src={image.thumbnail}
        alt={image.caption}
        
        onClick={() => {
          setLightboxIndex(index);
          setLightboxOpen(true);
      }}
      />
    ))}
  </div>
  
  {lightboxOpen ? (
  <Lightbox
    mainSrc={images[lightboxIndex].src}
    nextSrc={images[(lightboxIndex + 1) % images.length].src}
    prevSrc={images[(lightboxIndex + images.length - 1) % images.length].src}
    onCloseRequest={() => setLightboxOpen(false)}
    onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
    onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
  />
) : null}
</Tab.Pane>
      ))}
</Tab.Content>
</Tab.Container>
</section>
  )
}

export default Gallery