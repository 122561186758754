import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { ImageUrl } from '../Constants/Constants';
function ImageSlide(props) {
  const contents=props.contents
  //console.log(contents)
  return (
    <div className="banner-imageslide">
    {contents.map(content=>(
   <Carousel pause={false}  indicators={false} key={content.Id}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`}
          alt="PeaceGate"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`}
          alt="PeaceGate"
        />
      </Carousel.Item>
    </Carousel>
))}
</div>
  )
}

export default ImageSlide