import React from 'react'
import LeftArrow from "../../img/arrow-left.svg"
import PeaceGateIcon from "../../img/peacegate-icon.jpg"
import MenuImg from "../../img/menu.svg"
import { useNavigate } from 'react-router-dom';
function ServicesHeader() {
  const navigate = useNavigate();
  function handleBack() {
    navigate(-1)
  }
  return (
    <div className="page-header-con">
        <button className="backbtn" onClick={handleBack}><img src={LeftArrow} alt=""/></button>
        <img src={PeaceGateIcon} alt="peacegate"/>
        <button className="menubtn"><img src={MenuImg} alt=""/></button>
    </div>
  )
}

export default ServicesHeader