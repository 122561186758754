import MainPage from "./Pages/MainPage";
import TopicDescription from "./Pages/TopicDescription";
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom'
import DetailsDescription from "./Pages/DetailsDescription";
import SubDetailsDescription from "./Pages/SubDetailsDescription";
import Services from "./Pages/Services";
import NewPage from "./Pages/NewPage";
function App() {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<MainPage/>}/>
    <Route exact path="/announcement-description" element={<TopicDescription/>}/>
    <Route exact path="/details" element={<DetailsDescription/>}/>
    <Route exact path="/subdetails" element={<SubDetailsDescription/>}/>
    <Route exact path="/newpage" element={<NewPage/>}/>
    </Routes>
    </Router>
  );
}

export default App;
