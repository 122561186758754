import React from 'react'
import '../../css/Theme3.css'
import { ImageUrl } from '../../Constants/Constants'
import {useNavigate } from 'react-router-dom';
function Theme3(props) {
    //console.log(props.details)
    const Details=props.details
    const imagepath=props.Imagepath
    const navigate = useNavigate();
  return (
    <div class="container-fluid" >
    <div class="search-result">
      <div class="row"  >
    {Details.map(details=>(
          <div class="col-lg-12 d-flex justify-content-center">
             {imagepath==='topic'?<a onClick={() => navigate('/details', { state: details })}>
                  <div class="row justify-content-center" >
                      <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                          <span>
                            <img src={ImageUrl+`/assets/images/IIAM_AnnouncementSubContentImages/${details.ImageUrl}`} alt="peacegate" />
                          </span>
                      </div> 
                      <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                          <h5 class="capitalize">{details.Heading}</h5> 
                          <p dangerouslySetInnerHTML={{__html: details.Description}} style={{height: '50px', overflow: 'hidden'}} ></p>
                      </div>
                  </div>
              </a>:
              <a onClick={() => navigate('/subdetails', { state: details })}>
              <div class="row justify-content-center" >
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                      <span>
                        <img src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${details.ImageUrl}`} alt="peacegate" />
                      </span>
                  </div> 
                  <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                      <h5 class="capitalize">{details.Heading}</h5> 
                      <p dangerouslySetInnerHTML={{__html: details.Description}} style={{height: '50px', overflow: 'hidden'}} ></p>
                  </div>
              </div>
          </a>}
          </div>
))}
      </div>
    </div>
    </div> 
  )
}

export default Theme3