import React from 'react'
import {useLocation} from 'react-router-dom'
import ServicesHeader from '../Components/services components/ServicesHeader'
import Carousel from 'react-bootstrap/Carousel';
import { ImageUrl } from '../Constants/Constants'
function SubDetailsDescription() {
    const {state}=useLocation()
  return (
    <div className="page-wrapper-div">
    <ServicesHeader/>
<h2 className="page-heading">{state.Heading}</h2>
{state.Description?  <Carousel   indicators={false} className="carousel slide service-carousel">
  <Carousel.Item>
    <img
      className="w-100 service-desktop"
      src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${state.DescriptionImageUrl}`}
      alt="PeaceGate"
    />
    <img
      className="w-100 service-mobile"
      src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${state.ImageUrl}`}
      alt="PeaceGate"
    />
  </Carousel.Item>
  <Carousel.Item>
  <img
      className="w-100 service-desktop"
      src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${state.DescriptionImageUrl}`}
      alt="PeaceGate"
    />
    <img
      className="w-100 service-mobile"
      src={ImageUrl+`/assets/images/IIAM_AnnouncementSubDetailImages/${state.ImageUrl}`}
      alt="PeaceGate"
    />
  </Carousel.Item>
</Carousel>:null}

{state.Description?<div className="container service-single">
<p dangerouslySetInnerHTML={{__html: state.Description}}></p>
{state.DescriptionButton?<div className="row justify-content-center">
            <div className="col-lg-3"><button className="service-btn">{state.DescriptionButton}</button></div>
        </div>:null}
</div>:null}
</div>
  )
}

export default SubDetailsDescription