import React from 'react'
import {useLocation,useNavigate} from 'react-router-dom'
import ServicesHeader from '../Components/services components/ServicesHeader'
import Carousel from 'react-bootstrap/Carousel';
import { baseUrl, ImageUrl } from '../Constants/Constants'
import axios from 'axios';
import { useState,useEffect } from 'react'
import Theme1 from '../Components/Themes/Theme1';
import Theme2 from '../Components/Themes/Theme2';
import Theme3 from '../Components/Themes/Theme3';
import DefaultTheme from '../Components/Themes/DefaultTheme';
function TopicDescription() {
    const {state}=useLocation()
    const [Details, setDetails] = useState([])
    const navigate = useNavigate();
    console.log(state.ThemeType)
    useEffect(() => {
      axios.get(baseUrl+`/IIAM_AnnouncementSubContent?iiamannouncementcontentid=${state.Id}`)
  .then(function (response) {
   // console.log(response.data);
    setDetails(response.data)
  })
  .catch(function (error) {
    console.log(error);
  });
    }, [])
    
  return (
    <div className="page-wrapper-div">
        <ServicesHeader/>
    <h2 className="page-heading">{state.Heading}</h2>
    {state.Description?
    <Carousel   indicators={false} className="carousel slide service-carousel">
      <Carousel.Item>
        <img
          className="w-100 service-desktop"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${state.DescriptionImageUrl}`}
          alt="PeaceGate"
        />
        <img
          className="w-100 service-mobile"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${state.ImageUrl}`}
          alt="PeaceGate"
        />
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="w-100 service-desktop"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${state.DescriptionImageUrl}`}
          alt="PeaceGate"
        />
        <img
          className="w-100 service-mobile"
          src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${state.ImageUrl}`}
          alt="PeaceGate"
        />
      </Carousel.Item>
    </Carousel>
:null}
 <div className="container service-single">
    <p dangerouslySetInnerHTML={{__html: state.Description}}></p>
    {state.DescriptionButton?<div className="row justify-content-center">
                <div className="col-lg-3"><button className="service-btn">{state.DescriptionButton}</button></div>
            </div>:null}
    </div>
    {Details.length>0?
   <div className="container service-page-wrap">
 

        {/*Details.map(details=>(
                       <div className="col-lg-3 col-md-4 col-6" key={details.Id}>
                       <button className="service-item" onClick={() => navigate('/details', { state: details })}>
                           <font><i className="fas fa-heart"></i></font>
                           <span><img src={ImageUrl+`/assets/images/IIAM_AnnouncementSubContentImages/${details.ImageUrl}`} alt="peacegate"/></span>
                           <h6>{details.Heading}</h6>
                       </button>
                   </div>
        ))*/}
    <div className="row justify-content-center">
      {(() => {
        switch (state.ThemeType) {
          case 0:
            return <DefaultTheme details={Details} Imagepath='topic'/>;         
          case 1:
            return <Theme1 details={Details} Imagepath='topic'/>;
          case 2:
            return <Theme2 details={Details} Imagepath='topic' />;
          case 3:
            return <Theme3 details={Details} Imagepath='topic' />;
          default:
            return null;
        }

      })()}
    </div>
     </div>
  :null}
</div>
  )
}

export default TopicDescription