import React from 'react';

const Dots = ({ onClick, active }) => (
  <button
    style={{
      width: "15px",
      height: "15px",
      margin: "0 5px",
      backgroundColor: `${active ? "var(--blue)" : "white"}`,
      borderRadius: "50%",
      border: `2px solid var(--blue)`,
      outline: "none",
      cursor: "pointer"
    }}
    onClick={() => onClick()}
  />
);

export default Dots;