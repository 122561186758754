
import React,{useState,useEffect} from 'react'
import { ImageUrl } from '../Constants/Constants';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Tab, Nav} from 'react-bootstrap';
import Dots from './Dots';
function CaptionsHere2(props) {
    const Category=props.category
    const contents=props.contents
    const [activeTab, setActiveTab] =useState(0)
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
      }; 
      const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      }; 

    
  return (
    <section className="image-slider-wrap articles-wrap" >
    <div className="container">
        <h2>{props.Name}</h2>
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
        {Category.length>1?<Nav   className="justify-content-center" style={{ paddingBottom: '20px' }}>
      {Category.map((item, index)=> (
          <Nav.Item key={item.Id}>
          <Nav.Link key={item.Id} style={{ color: 'black' }} eventKey={index}>{item.Heading}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>:null}
      <Tab.Content>
      {Category.map((item, index) => (
      <Tab.Pane key={item.Id} eventKey={index}>
        <div className="trending-videos" style={{ position: "relative" }}>
            <Carousel key={item.Id} responsive={responsive}
            swipeable={false}   draggable={false}
      infinite={true} 
      autoPlay={true}>
    {contents.filter(content => content.AnnouncementGroupId===item.Id).map(content => (
            <button key={content.Id} className="trendvideo-item" >
            <span key={content.Id}>
                <img src={ImageUrl+`/assets/images/IIAM_AnnouncementContentImages/${content.ImageUrl}`} alt="peacegate"/>
                <h6>{content.Heading}</h6>
            </span>
            </button>
        ))}
     </Carousel>
     </div>
        </Tab.Pane>
        ))}
        </Tab.Content>
        </Tab.Container>
    </div>
</section>
  )
}

export default CaptionsHere2