import axios from 'axios'
import React, { useState, useEffect }  from 'react'
import { baseUrl } from '../Constants/Constants';
import { Tab, Nav} from 'react-bootstrap';
import '../css/ContactDetails.css'
function ContactDetails(props) {
    const [ContactDetails, setContactDetails] = useState([])
    const [activeTab, setActiveTab] =useState(0)
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
      }; 
    useEffect(() => {
        axios.get(baseUrl+`/Centers?mmm=1`)
        .then(response => {
         //console.log(response.data)
         setContactDetails(response.data)
        })
    }, [])
    function redirectToMap(latitude, longitude) {
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      //window.location.href = url;
      window.open(url, '_blank');
    }
    function sendEmail(email) {
      //window.location.href =`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
      const url=`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
      window.open(url,'_blank')
    }
    function openDialpad(number) {
      window.location.href = `tel:${number}`;
    }
  return (
    <section className=" container articles-wrap">
  <h2 >Contact us</h2>
  <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} >
     {ContactDetails.length>1?<Nav   className="justify-content-center">
      {ContactDetails.map((item, index)=> (
          <Nav.Item key={item.Id} >
          <Nav.Link key={item.Id} style={{ color: 'black' }} eventKey={index}>{item.Name}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>:null}
      <Tab.Content style={{ paddingTop: '20px' }}>
      {ContactDetails.map((item, index) => (
      <Tab.Pane key={item.Id} eventKey={index}>
          <div className="innerpage-wrapper " >
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="contact-details-item">
                        <span  onClick={() => redirectToMap(item.Latitude,item.Longitude)} style={{cursor:'pointer'}}><i className="fa-solid fa-location-dot" ></i></span>
                        <h5>Address</h5>
                        <p >{item.Address} <br />{item.State},{item.Country}</p>
                    </div>

                    <div className="contact-details-item">
                        <span  onClick={() => sendEmail(item.Email)} style={{cursor:'pointer'}}><i className="fa-regular fa-envelope"></i></span>
                        <h5>Email</h5>
                        <h6><a href="">{item.Email}</a></h6>
                        <br/>
                        
                    </div>

                    <div className="contact-details-item">
                        <span onClick={() => openDialpad(item.Mobile)} style={{cursor:'pointer'}}><i className="fa-solid fa-phone"></i></span>
                        <h5>Phone</h5>
                        <h6><a href="">{item.Mobile}</a></h6>
                        <h6><a href="">{item.Phone}</a></h6>
                    </div>
                </div>
                <div className="col-lg-7 letsconnect-con">
                    <h4>Let's Connect</h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-4"><input type="text" placeholder="Your Name"/></div>
                        <div className="col-lg-4 col-md-4"><input type="text" placeholder="Email"/></div>
                        <div className="col-lg-4 col-md-4"><input type="text" placeholder="Phone"/></div>
                        <div className="col-lg-12"><textarea name="" placeholder="Message" id="" cols="30"
                                rows="10"></textarea></div>
                        <div className="col-lg-3 col-md-4"><button>Submit</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
     </Tab.Pane>
      ))}
  </Tab.Content>
        </Tab.Container>

</section>
  )
}


export default ContactDetails